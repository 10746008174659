<template>
	<div>
		<v-btn color="primary" @click="showModal = true">+ New Emote</v-btn>
		<v-dialog v-model="showModal" width="500">
			<v-card>
				<v-card-title> 😃 Suggest a new emote! </v-card-title>
				<v-card-text>
					<v-form>
						<v-select
							v-model="selectedCategory"
							:items="categories"
							label="Select a Category"
							required
						></v-select>
						<FileUpload ref="fileUpload" @file="selectedFile = $event" />
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="primary"
						class="mt-n3 mr-2 mb-1"
						@click="upload"
						:disabled="!ready"
						:loading="loading"
						>Submit</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import FileUpload from '@/components/FileUpload';

export default {
	name: 'EmotesAddButton',
	components: { FileUpload },
	data: () => ({
		showModal: false,
		categories: [],
		selectedCategory: null,
		selectedFile: null,
		loading: false,
	}),
	computed: {
		ready() {
			return !!this.selectedCategory && !!this.selectedFile;
		},
	},
	methods: {
		async upload() {
			this.loading = true;
			const opt = {
				text:
					"Any images that are inappropriate or breaks Discord's Terms of Service are not allowed. " +
					'Files can not be real life images or videos. It must be drawn or animated. ' +
					'WE WILL BAN YOU if you fail to follow these rules.',
				width: 500,
				persistent: true,
				buttons: [
					{
						text: 'I Agree',
						color: 'primary',
						returns: true,
					},
					{
						text: 'Cancel',
						color: 'gray',
						outlined: true,
						returns: false,
					},
				],
			};
			const result = await this.$modal(opt).showWarn();
			if (result) {
				try {
					await this.$store.dispatch('uploadNewEmote', {
						file: this.selectedFile,
						category: this.selectedCategory,
					});
					this.showModal = false;
					this.clear();
					this.showSuccess();
				} catch (err) {
					this.showError(err.response.data.error);
				}
			}
			this.loading = false;
		},
		clear() {
			this.selectedFile = null;
			this.selectedCategory = null;
			this.$refs.fileUpload.removeFile();
		},
		showError(err) {
			let text;
			switch (err) {
				case 'TooManyUploads':
					text = 'You can only upload up to 10 images per day!';
					break;
				case 'GifTooLong':
					text = 'The gif you uploaded is too long.';
					break;
				default:
					text =
						'Hmm... It seems like there was an error uploading your emote! Please try a different image.';
			}
			const opt = {
				text,
				width: 300,
				imgUrl: 'owo-cry.png',
				buttons: [
					{
						text: 'Okay...',
						color: 'primary',
					},
				],
			};
			return this.$modal(opt).showError();
		},
		showSuccess() {
			const opt = {
				text: 'OwO, you submitted an emote! Once it is approved by the mods, it will be added to the bot! 🎉',
				width: 500,
				buttons: [
					{
						randomYesText: true,
						color: 'primary',
					},
				],
			};
			return this.$modal(opt).showInfo();
		},
	},
	async mounted() {
		this.categories = await this.$store.dispatch('getEmoteCategories');
		this.categories.sort();
	},
};
</script>

<style scoped></style>
