<template>
	<div class="emotes-page">
		<div
			class="header mt-3"
			:class="{ 'header-mobile': isMobile, 'header-small': isSmall }"
		>
			<EmotesAddButton
				class="header-button"
				:class="{ 'header-button-small': isSmall }"
			/>
			<div
				class="header-title"
				:class="{
					'header-title-mobile': isMobile,
					'header-title-small': isSmall,
				}"
			>
				<div class="text-h4">Pending Emotes List</div>
				<div class="text-subtitle-1 mt-1 subtext">
					Upvote emotes you like! They will be more likely to get approved by a
					moderator. Inappropriate emotes or real life images/videos are not
					allowed.
				</div>
			</div>
			<EmotesFilterSelect @sortBy="sortBy = $event" @order="order = $event" />
		</div>
		<div class="mt-4 emotes-list">
			<EmoteCard
				v-for="emote in emotes"
				:key="emote._id"
				:emote="emote"
				class="emote-card"
			/>
		</div>
		<v-btn
			v-if="!endOfPage"
			v-observe-visibility="buttonVisible"
			class="mt-4"
			outlined
			color="secondary"
			:loading="loadingMore"
			@click="nextPage"
		>
			<v-icon color="secondary" class="mr-2">mdi-refresh</v-icon>
			Load More
		</v-btn>
	</div>
</template>

<script>
import EmotesAddButton from '@/views/emotes/components/EmotesAddButton';
import EmotesFilterSelect from '@/views/emotes/components/EmotesFilterSelect';
import EmoteCard from '@/views/emotes/components/EmoteCard';

export default {
	name: 'EmotesPage',
	components: { EmotesAddButton, EmoteCard, EmotesFilterSelect },
	data: () => ({
		loadingMore: false,
		page: 1,
		sortBy: null,
		endOfPage: false,
		order: null,
	}),
	computed: {
		emotes() {
			return this.$store.getters.pendingEmotes;
		},
		isMobile() {
			return this.$vuetify.breakpoint.width < 840 && !this.isSmall;
		},
		isSmall() {
			return this.$vuetify.breakpoint.width < 455;
		},
	},
	watch: {
		sortBy(to, from) {
			if (to === from) return;
			this.resetPage();
		},
		order(to, from) {
			if (to === from) return;
			this.resetPage();
		},
	},
	methods: {
		async nextPage() {
			this.loadingMore = true;
			this.page++;
			const res = await this.$store.dispatch('getPendingEmotes', {
				page: this.page,
				sortBy: this.sortBy,
				order: this.order,
			});
			if (!res.length) {
				this.endOfPage = true;
			}
			this.loadingMore = false;
		},
		async resetPage() {
			this.loadingMore = true;
			this.page = 1;
			await this.$store.dispatch('clearPendingEmotes');
			await this.$store.dispatch('getPendingEmotes', {
				page: this.page,
				sortBy: this.sortBy,
				order: this.order,
			});
			this.loadingMore = false;
		},
		buttonVisible(isVisible) {
			if (this.loadingMore || !isVisible) return;
			this.nextPage();
		},
	},
};
</script>

<style scoped lang="scss">
.emotes-page {
	width: 100%;
	height: min-content;
	padding: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.header-title {
	display: flex;
	flex-direction: column;
	align-items: center;
	flex-grow: 1;

	div {
		text-align: center;
	}
}

.header-title-mobile {
	order: 3;
}

.header-title-small {
	order: -1;
	margin-bottom: 20px;
}

.subtext {
	max-width: 600px;
	color: rgba(255, 255, 255, 0.8);
}

.emotes-list {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.emote-card {
	margin: 5px;
}

.header {
	display: flex;
	width: 100%;
	justify-content: space-between;
}

.header-mobile {
	flex-wrap: wrap;
}

.header-small {
	flex-direction: column;
	align-items: center;
}

.header-button {
	width: 200px;
}

.header-button-small {
	width: min-content !important;
	margin-bottom: 25px;
	order: 3;
}
</style>
