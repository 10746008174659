<template>
	<div class="filter-buttons">
		<v-select
			v-model="selected"
			:items="sortByItems"
			label="Sort by"
			class="select"
			outlined
			dense
			@input="$emit('sortBy', $event)"
		></v-select>
		<v-btn
			outlined
			class="sort-button"
			v-ripple="{ class: `primary--text` }"
			@click="toggleSort"
		>
			<v-icon small class="sort-icon" :class="{ 'upside-down': ascend }"
				>mdi-arrow-down</v-icon
			>
		</v-btn>
	</div>
</template>

<script>
export default {
	name: 'EmotesFilterSelect',
	data: () => ({
		sortByItems: [
			{ text: 'likes', value: 'likes' },
			{ text: 'created date', value: 'createdAt' },
		],
		selected: 'likes',
		order: 'desc',
	}),
	computed: {
		ascend() {
			return this.order === 'asc';
		},
	},
	methods: {
		toggleSort() {
			if (this.order === 'asc') {
				this.order = 'desc';
			} else {
				this.order = 'asc';
			}
			this.$emit('order', this.order);
		},
	},
	mounted() {
		this.$emit('sortBy', this.selected);
		this.$emit('order', this.order);
	},
};
</script>

<style scoped>
.select {
	max-width: 200px;
}

.filter-buttons {
	display: flex;
}

.sort-button {
	padding: 0px 0px !important;
	min-width: 36px !important;
	border-color: rgba(255, 255, 255, 0.24) !important;
	height: 40px !important;
	margin-left: 3px;
}

.sort-icon {
	transition: all 0.5s ease;
}

.upside-down {
	-webkit-transform: rotate(180deg);
	-moz-transform: rotate(180deg);
	-o-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
}
</style>
